.title h1 {
  font-size: 24px;
}

.title h1::after {
  /*background-image: linear-gradient(90deg, #8cc9f0, #efa8b0);*/
  background-color: #8cc9f0;
  display: block;
  content: "";
  width: 4em;
  height: 2px;
  margin: 0.7em 0 1em 0;
  border-radius: 2px;
}

.menu-row {
  display: flex;
  justify-content: space-between;
}

.big-title {
  position: relative;
  padding-right: 2em;
}

.big-title h1 {
  font-size: 34px;
}

.big-title h1::after {
  /*background-image: linear-gradient(90deg, #8cc9f0, #efa8b0);*/
  background-color: #8cc9f0;
  display: block;
  content: "";
  width: 5em;
  height: 2px;
  margin: 0.7em 0 1em 0;
  border-radius: 2px;
}

.menu-container {
  vertical-align: middle;
  text-align: center;
  margin: auto 2em;
  padding: 0 3em;
}

.menu-container ul {
  display: block;
}

.menu-container ul li {
  font-size: 20px;
  float: left;
  margin: 0 1em;
}

.menu-container ul li:hover {
  color: #4ebafd;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .title h1 {
    font-size: 22px;
  }

  .big-title h1 {
    font-size: 24px;
  }

  .menu-container {
    padding: 0em;
    margin: auto 3em;
  }

  .menu-container ul li {
    font-size: 16px;
  }
}

@media (max-width: 650px) {
  .title h1 {
    font-size: 22px;
  }

  .menu-container {
    display: none;
  }

  .big-title h1 {
    font-size: 20px;
  }
}
