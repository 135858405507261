.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2em 0;
}

.photo-card {
  flex: 0 1 calc(33%);
  text-align: center;
  margin-bottom: 2em;
}

.photo-card img {
  width: 300px;
  border-radius: 1em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 8px 12px 0 rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1120px) {
  .photo-card {
    flex: 0 1 calc(50%);
    margin-bottom: 2em;
  }

  .photo-card img {
    width: 90%;
  }
}

@media screen and (max-width: 650px) {
  .photo-card {
    flex: 0 1 calc(100%);
    margin-block-end: 2em;
  }

  .photo-card img {
    width: 90%;
  }
}
