.project-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.project-card {
  flex: 0 1 calc(50% - 3em);
  text-align: center;
  border-radius: 0.5em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 8px 12px 0 rgba(0, 0, 0, 0.1);
  margin: 2em 1.5em;
}

.image {
  position: relative;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  text-align: center;
}

.project-card img {
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  width: 100%;
  /* undraggable image */
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.link-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.5s ease;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  background-color: gray;
}

.image:hover .project-image {
  -webkit-filter: blur(4px);
  filter: blur(4px);
}

.image:hover .link-overlay {
  opacity: 0.85;
}

.link {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.link a {
  display: inline-block;
  margin: 0 2em;
  font-size: 1.5em;
  color: black;
}

.link a:hover {
  color: #72c5f8;
}

.noHover {
  pointer-events: none;
}

.link img {
  width: 60px;
  vertical-align: middle;
  margin: 0.6em 0.3em;
}

.card-content {
  padding: 0 2em;
  margin-bottom: 0.8em;
}

.card-content h3 {
  position: relative;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.card-content h3 span {
  position: absolute;
  top: 0.26em;
  margin-left: 1em;
  font-size: 0.7em;
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  text-transform: uppercase;
  padding: 5px 7px 5px 7px;
  margin: -5px 0 0 7px;
}

.green-label {
  background: #00b27f center no-repeat;
}

.blue-label {
  background: #72c5f8 center no-repeat;
}

.card-content p {
  margin-block-start: 0em;
  margin-block-end: 0.5em;
  line-height: 1.5em;
  font-size: 15px;
}

.gray-text {
  color: gray;
}

.projectType-active {
  color: #4ebafd;
}

@media (max-width: 920px) {
  .card-content h3 span {
    display: none;
  }

  .card-content h3 {
    font-size: 16px;
  }

  .card-content p {
    font-size: 12px;
  }

  .link a {
    font-size: 1em;
  }

  .link img {
    width: 50px;
  }
}

@media (max-width: 650px) {
  .card-content {
    padding: 0 0.5em;
  }

  .card-content h3 span {
    display: none;
  }

  .project-card {
    flex: 0 1 calc(100%);
    margin: 1.5em 0.5em;
  }

  .card-content h3 {
    font-size: 18px;
  }

  .card-content p {
    font-size: 14px;
    line-height: 1.2em;
  }

  .link a {
    font-size: 1em;
  }

  .link img {
    width: 40px;
  }
}
