.card {
  display: block;
  width: 100%;
  height: 100%;
  padding: 1em 1em;
  flex: 0 1 calc(50% - 3em);
  margin-bottom: 2em;
  border-radius: 1em;
}

.card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 8px 12px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease 0s;
}

.card img {
  width: 100px;
  min-height: 100px;
  margin-bottom: 0.4em;
}

.card h2 {
  margin-block-start: 0.4em;
  margin-block-end: 0.4em;
}

.card h4 {
  margin-block-start: 0.4em;
  margin-block-end: 1em;
}

.card .line {
  margin: 0;
  line-height: 1.6;
  font-size: 15px;
}

@media (max-width: 950px) {
  .card h2 {
    font-size: 1.2em;
  }

  .card h4 {
    font-size: 1em;
  }

  .card .line {
    font-size: 14px;
  }
}

@media (max-width: 650px) {
  .card {
    flex: 0 1 calc(100%);
  }
}
