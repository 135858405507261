.home::after {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.8;
}

.home {
  background-image: url(../../images/snowboard.jpg);
  width: 100%;
  height: 100vh;
  position: relative;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home > * {
  z-index: 10;
  position: relative;
  color: white;
}

.home .wrapper-content {
  position: absolute;
  top: 15%;
  left: 8%;
  font-size: 40px;
  width: 50%;
}

.home .wrapper-content .hashtags {
  width: 100%;
  height: auto;
}

.home .wrapper-content ul {
  display: inline-block;
}

.home .wrapper-content li {
  display: inline-block;
  font-size: 16px;
}

.label-pill {
  display: block;
  font-size: 14px;
  color: #fff;
  background: #00b27f center no-repeat;
  font-weight: 600;
  border-radius: 10px;
  text-transform: uppercase;
  padding: 5px 7px 5px 7px;
  margin: -5px 0 0 7px;
}

.label-lightblue {
  background: #8cc9f0;
}

.label-lightpink {
  background: #efa8b0;
}

.social-media-link {
  margin-top: 1em;
}

.social-media-link ul li {
  margin: 0 1em;
  vertical-align: middle;
}

#qrcode {
  opacity: 0;
}

#qrcode.displayed {
  opacity: 1;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.social-media-link img {
  width: 50px;
  color: #636363;
}

.grayscale {
  filter: invert(100%);
  -webkit-filter: invert(80%);
}
.grayscale:hover {
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

@media (max-width: 1120px) {
  .home .wrapper-content {
    font-size: 30px;
    width: 50%;
  }
}

@media (max-width: 700px) {
  .home {
    height: 450px;
  }

  .home .wrapper-content {
    position: absolute;
    top: 15%;
    left: 5%;
    font-size: 23px;
    width: 90%;
    text-align: center;
  }

  .hashtags {
    display: none;
  }

  .social-media-link ul {
    display: block;
    width: 70%;
    padding-top: 1em;
  }

  .social-media-link img {
    width: 40px;
  }
}
