.about-me {
  display: flex;
  align-items: center;
  width: 76em;
  max-width: calc(100% - 4em);
  margin: 3em auto;
}

.self-image {
  display: inline-block;
  position: relative;
  margin-right: 2em;
  width: 80%;
}

.self-image img {
  display: inline-block;
  position: relative;
  width: 100%;
  border-radius: 1em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 12px 24px 0 rgba(0, 0, 0, 0.19);
  flex: 1;
}

.content {
  display: inline-block;
  position: relative;
  font-weight: 300;
}

.content h1 {
  font-weight: 300;
  font-size: 34px;
}

.content h1::after {
  /*background-image: linear-gradient(90deg, #8cc9f0, #efa8b0);*/
  background-color: #8cc9f0;
  display: block;
  content: "";
  width: 4em;
  height: 2px;
  margin: 0.7em 0 1em 0;
  border-radius: 2px;
}

.content p {
  font-size: 18px;
  margin-bottom: 1.5em;
  line-height: 1.5em;
  width: 100%;
}

.skillsets ul {
  list-style: none;
  padding: 0;
  font-size: 14px;
}

.skillsets ul li {
  margin: 1em 0;
}

.resume-btn {
  border: 0px solid #fff;
  border-radius: 5px;
  color: #72c5f8;
  display: inline-block;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin-top: 2em;
  overflow: hidden;
  padding: 14px 42px;
  position: relative;
  text-transform: uppercase;
  z-index: 9;
  background: white;
}

.resume-btn:hover {
  color: white;
  background-color: #72c5f8;
}

@media (max-width: 1050px) {
  .self-image {
    width: 100%;
  }
  .content h1 {
    font-size: 30px;
  }
  .resume-btn {
    margin-top: 1em;
    padding: 14px 40px;
  }
}

@media (max-width: 900px) {
  .about-me {
    display: block;
    width: 70%;
  }

  .self-image {
    display: block;
    text-align: center;
    margin: 0 auto;
    width: 100%;
  }

  .content p {
    font-size: 22px;
  }
}

@media (max-width: 650px) {
  .about-me {
    display: block;
    width: 80%;
  }

  .content h1 {
    font-size: 26px;
  }

  .content p {
    font-size: 20px;
    line-height: 1.3em;
    margin-bottom: 1em;
  }

  .skillsets ul {
    font-size: 16px;
  }
}
